footer {
  display: flex;
  align-items: center;
  color: white;
  flex-direction: column;

  align-items: center;
  padding-bottom: 30px;
  max-width: 90%;
  margin: auto;
  font-size: 12px;

  ul {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;

    li {
      margin-bottom: 5px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  a {
    color: white;
  }

  .ot-sdk-show-settings {
    cursor: pointer;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter&family=Zen+Dots&display=swap");

.mainPage {
  // background-color: ;
  min-height: 100vh;
  font-family: "Zen Dots";

  background: linear-gradient(
      180deg,
      #1d0550 10.24%,
      rgba(82, 0, 255, 0.56) 32.61%
    ),
    url(../assets/grunge.png);
  background-blend-mode: lighten;
  // opacity: 0.9;

  .weaIcon {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h1 {
    color: white;
    font-family: "Bebas Neue";
    font-size: 48px;
  }

  .descriptionContainer {
    background: linear-gradient(
      180deg,
      rgba(82, 0, 255, 0.56) 0%,
      rgba(206, 184, 238, 0) 100%
    );
    border-radius: 5px;
    padding: 30px;
    color: white;
    font-size: 21px;
    width: 600px;
    max-width: 90%;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    font-family: "Bebas Neue";

    p {
      font-size: 42px;
      line-height: 56px;

      span {
        color: #ee88ff;
      }
    }
  }

  .contentContainer {
    max-width: 100vw;
    width: 728px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    .linksContainer {
      width: 100%;
      margin-bottom: 50px;

      .linkContainer {
        width: 100%;
        background-size: cover;
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        justify-content: center;
        background-position: center;
        align-items: center;
        text-align: center;

        @media screen and (max-width: 728px) {
          height: 400px;
        }

        p {
          font-size: 32px;
        }

        &.blurred {
          p {
            font-size: 32px;
            width: 100%;
            justify-content: center;
            display: flex;
            vertical-align: center;
            align-items: center;
          }

          h2 {
            width: 100%;
          }

          .blurBG {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(10px);
          }
        }
        h2 {
          font-size: 72px;
          text-align: center;
          margin: auto;
          margin-top: 50px;
          margin-bottom: 50px;
          max-width: 90%;
          text-align: center;

          @media screen and (max-width: 728px) {
            font-size: 56px;
          }

          @media screen and (max-width: 400px) {
            font-size: 48px;
          }
        }

        a {
          border: solid 1px white;
          padding: 10px 30px;
          color: white;
          text-transform: uppercase;
          border-radius: 50px;
          text-decoration: none;
          font-size: 32px;
          transition: 0.3s all ease-in-out;

          @media screen and (max-width: 728px) {
            font-size: 24px;
          }

          &:hover {
            background: white;
            color: black;
          }
        }
      }
    }
  }
}

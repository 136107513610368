@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Bebas+Neue&family=Inter&family=Zen+Dots&display=swap");

.artistPage {
  // background-color: ;
  min-height: 100vh;
  font-family: "Zen Dots";

  background: linear-gradient(
      180deg,
      #1d0550 10.24%,
      rgba(82, 0, 255, 0.56) 32.61%
    ),
    url(../../assets/grunge.png);

  background-blend-mode: lighten;
  display: flex;
  flex-direction: column;
  align-items: center;
  // opacity: 0.9;

  .weaIcon {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h1 {
    color: white;
    font-family: "Bebas Neue";
    font-size: 48px;
  }

  .lotPhrase {
    color: white;
    font-size: 21px;
    max-width: 728px;
    width: 50%;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;

    @media screen and (max-width: 728px) {
      width: 90%;
    }

    span {
      color: #c57cff;
      display: block;
    }
  }

  .howMuchWinners {
    color: white;
    font-size: 21px;
    max-width: 728px;
    text-transform: uppercase;
    color: #e5c5ff;
    width: 50%;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;

    @media screen and (max-width: 728px) {
      width: 90%;
    }

    span {
      color: #c57cff;
      display: block;
    }
  }

  .backHome {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 30px;

    img {
      width: 100%;
    }
  }

  .contentContainer {
    width: 728px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    max-width: 90%;
    margin: auto;
    padding-bottom: 50px;
    position: relative;
    margin-bottom: 100px;

    &:before {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      height: 0px;
      border-top: solid 1px white;
      width: 50%;
    }

    h2 {
      text-transform: uppercase;
      font-size: 21px;
      margin-top: 50px;
      margin-bottom: 40px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        height: 0px;
        border-top: solid 1px white;
        width: 80%;
      }
    }

    .lots {
      text-align: center;
      font-size: 16px;
      line-height: 25px;
      text-align: justify;
      color: white;
      font-family: "Montserrat";

      @media screen and (max-width: 728px) {
        font-size: 14px;
      }

      span {
        display: block;
        margin-bottom: 20px;
        color: #e5c5ff;
        font-weight: bold;
        font-size: 24px;
      }
    }

    #formulaire {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 30px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          input[type="text"],
          input[type="email"] {
            width: 100%;
            margin-top: 15px;
            margin-bottom: 25px;
            border: none;
            border-radius: 50px;
            padding: 10px 10px;
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }

      ul {
        li {
          //   display: flex;
          //   align-items: flex-start;

          input {
            width: 20px;
            height: 20px;
            border: none;
            margin-top: 0px;
            margin-right: 20px;
          }

          label {
            margin-top: 5px;
          }
        }
      }

      button {
        border: solid 2px white;
        background: none;
        padding: 10px 20px;
        border-radius: 50px;
        color: white;
        font-family: "Zen Dots";
        text-transform: uppercase;
        font-size: 18px;
        margin-top: 30px;
        cursor: pointer;

        &:hover {
          color: black;
          background: white;
        }
      }
    }
  }

  .priseEnCompte {
    background: #1d0550;
    border: 1px solid #e5c5ff;
    border-radius: 17px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 50px;

    img {
      margin-bottom: 30px;
    }
  }

  .pushCover {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-transform: uppercase;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
      text-align: center;
    }

    a {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 300px;
      max-width: 90%;
      margin: auto;
      border: solid 2px white;
      margin-bottom: 30px;
    }

    .listen {
      border: solid 2px white;
      background: none;
      padding: 10px 20px;
      border-radius: 50px;
      color: white;
      font-family: "Zen Dots";
      text-transform: uppercase;
      font-size: 18px;
      text-decoration: none;
      margin-top: 30px;
      cursor: pointer;

      &:hover {
        color: black;
        background: white;
      }
    }
  }

  .boxPresentation {
    background: linear-gradient(
        0deg,
        rgba(0, 179, 190, 0.2),
        rgba(0, 179, 190, 0.2)
      ),
      radial-gradient(
          70.06% 72.72% at 53.98% 50%,
          rgba(#ffffff, 0.6) 3.35%,
          rgba(#44f4ff, 0.6) 16.51%,
          rgba(#c57cff, 0.6) 69.79%,
          rgba(#c57cff, 0.6) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    // opacity: 0.6;
    border: 1px solid #ffffff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 300px;
    aspect-ratio: 1/1;
    border-radius: 50%;

    img {
      width: 60%;
    }
  }
}
